@import "../../../../../styles/description.scss";

.textFieldsGrid {
  display: grid;
  grid-gap: 16px;

  .userInfoGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}

.agreementWrapper {
  display: flex;
  flex-direction: column;

  @include mobile {
    margin-bottom: 20px;
  }

  .agreementCheckbox {
    display: flex;
    align-items: flex-start;
  }
}

.markdown {
  p {
    @include little-text;

    margin: 0;
  }

  a {
    @include little-text;
  }
}
