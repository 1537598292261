@import "../../../../../styles/description.scss";

.container {
  width: 618px;

  @include mobile {
    width: 100%;
  }

  @include mobile-mini {
    width: 270px;
  }

  .loader {
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    margin-top: 24px;
    @include mobile {
      max-width: 300px;
    }
  }

  .successMessage {
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 20px;

      @include mobile {
        margin-bottom: 16px;
      }
    }
  }
}
