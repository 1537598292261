@import "../../../styles/description.scss";

.container {
  overflow: hidden;

  * + * {
    margin-top: 20px;
  }

  h1 {
    @include h1;
    margin: 0 0 40px 0;
  }

  h2 {
    @include h2;
  }

  h2 {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
  }

  p,
  span {
    @include simple-text;
    width: 100%;
  }

  a {
    @include button-text;
    color: $color-red;
  }

  blockquote {
    padding: 32px 24px;
    background-color: var(--bg);
    border-radius: 20px;

    @include laptop {
      padding: 24px;
    }

    @include mobile {
      padding: 20px 24px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 0 10px;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 2px;

    li {
      @include simple-text;

      &::marker {
        color: $color-blue-primary;
        height: 100%;
        margin: auto 0;
        position: absolute;
        top: 12px;
        font-size: 16px;
      }

      @include mobile {
        text-align: left;
      }

      ul,
      ol {
        margin-top: 8px;
        margin-left: 18px;
      }
    }

    li + li {
      margin-top: 8px;
    }
  }

  img {
    max-width: 100%;
  }

  figure[class*="table"] {
    overflow-x: auto;
    padding-bottom: 10px;
  }

  figure[class*="image"] {
    img {
      width: 100%;
    }
  }

  figure[class*="image-style-align-left"] {
    float: left;
    margin-right: 10px;
  }

  figure[class*="image-style-align-center"] {
    margin: 0 auto;
  }

  figure[class*="image-style-align-right"] {
    float: right;
    margin-left: 10px;
  }

  mark[class*="marker-yellow"] {
    background-color: yellow;
  }

  mark[class*="marker-green"] {
    background-color: greenyellow;
  }

  mark[class*="marker-pink"] {
    background-color: pink;
  }

  mark[class*="marker-blue"] {
    background-color: deepskyblue;
  }

  figcaption {
    text-align: center;
    font-size: 12px;

    a {
      font-size: 12px;
    }
  }

  ol {
    list-style: decimal inside;

    li {
      @include simple-text;

      ul,
      ol {
        margin-top: 8px;
        margin-left: 18px;
      }

      @include mobile {
        text-align: left;
      }
    }

    li + li {
      margin-top: 8px;
    }
  }
}
