.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-left: auto;
  user-select: none;
  cursor: pointer;

  .text {
    margin-left: auto;
  }

  .bottom {
    margin-left: 0;
  }
}

.button {
  flex-basis: 34px;
  min-width: 34px;
  height: 34px;
  border: 1px solid var(--bg);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 150ms ease;
  margin-left: auto;
  user-select: none;

  &.open {
    transform: rotate(180deg);
    transition: transform 150ms ease;
  }

  .arrow {
    margin-top: 2px;
  }
}
