@import "../../../styles/description.scss";

.sectionContainer {
  @include content;

  .title {
    text-align: center;
  }

  .cardsContainer {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;

    .cardLink {
      @include h3;
      height: 176px;
      grid-column: span 2;
      border-radius: 10px;
      box-shadow: var(--shadow1);
      position: relative;
      transition-duration: 0.3s;

      @include mobile {
        grid-column: span 6;
      }

      &:hover {
        color: $color-red;
        box-shadow: var(--shadow1), 0 0 0 1.5pt var(--corporate);
      }

      &_large {
        @media (min-width: $screen-m) {
          grid-column: span 3;
          height: 210px;
        }
      }

      .image {
        border-radius: 10px;
        display: block;
      }

      .text {
        position: absolute;
        left: 32px;
        bottom: 20px;
      }
    }

    @include mobile {
      margin-top: 20px;

      &_evenCardCount {
        .cardLink {
          height: 162px;
          grid-column: span 3;

          @include mobile-mini {
            height: calc(min((100vw - 8px) / 2, 140px));
          }

          .text {
            left: 20px;
          }
        }

        @include mobile-mini {
          grid-gap: 8px;
        }
      }
    }
  }

}
