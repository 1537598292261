@import "../../../styles/description.scss";

.fileInput {
  .inputWrapper {
    position: relative;
  }

  .input {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    @include mobile {
      flex-direction: column;
    }

    .button {
      width: 355px;
      @include mobile {
        width: 100%;
        padding: 12px 6px;
      }
    }
  }

  .acceptWrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .accept {
      max-width: 164px;
      margin-top: 4px;
    }
  }

  .filesList {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}

.file {
  display: flex;
  align-items: center;

  .contentWrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .information {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .fileNameWrapper {
    width: 191px;
  }

  .fileName {
    @include simple-text;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      fill: $color-additional-light;
    }
  }

  .deleteIcon {
    path {
      fill: $color-additional;
    }
  }

  .fileSize {
    @include little-text;
    color: $color-additional;
  }

  .delete {
    margin-left: 9px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.errorMessage {
  @include little-text;
  color: var(--error);
  margin-top: 5px;
  margin-left: 5px;
}
